<template>
  <div>
    <b-card>
      <Table
          large
          :fields="fields"
          :url="url"
          responsive="lg"
          :title="$t('transaction_currency')"
      >

        <!--:items="transactions"-->
        <!--class="position-relative"-->
        <!--hover-->
        <!--responsive-->
        <!--striped-->

        <template #cell(company_id)="data">
          <p class="badge badge-light-primary" style="width:55px;" v-if="data.item.company_id">
            <span>C-{{ data.item.company_id }}</span>
          </p>
        </template>
        <template #cell(amount)="data">
          <span v-if="data.item.amount">{{ amountFormat(data.item.amount, data.item.currency.name) }}</span>
          <span v-else>{{ amountFormat(data.item.crypto_amount, data.item.currency.name) }}</span>
        </template>

        <template #cell(real_amount)="data">
          <span v-if="data.item.real_amount">{{
            amountFormat(data.item.real_amount, data.item.exchange_currency.name)
          }}</span>
          <span v-else>{{ amountFormat(data.item.crypto_real_amount, data.item.exchange_currency.name) }}</span>

          <!-- <feather-icon v-if="data.item.status == 0" @click="editAction(data.item)" class="mx-1" icon="EditIcon"/> -->
          <feather-icon @click="editAction(data.item)" class="mx-1" icon="EditIcon"/>

        </template>
        <template #cell(exchange_amount)="data">

          <span v-if="data.item.exchange_amount">{{
            amountFormat(data.item.exchange_amount, data.item.exchange_currency.name)
          }}</span>
          <span v-else>{{ amountFormat(data.item.exchange_crypto_amount, data.item.exchange_currency.name) }}</span>

          <!--<feather-icon v-if="data.item.status == 0" @click="editAction(data.item.id)" class="mx-1" icon="EditIcon"/>-->

          <feather-icon @click="editExchangeAmount(data.item)" class="mx-1" icon="EditIcon"/>

        </template>
        <template #cell(fee_hive_amount)="data">
          <span>{{ amountFormat(data.item.fee_hive_amount, data.item.exchange_currency.name) }}</span>
          <feather-icon @click="editDiscount(data.item)" class="mx-1" icon="EditIcon"/>
        </template>

        <template #cell(status)="data">
          <span class="text-warning" v-if="data.item.status == 0"> {{ $t("pending") }} </span>
          <span class="text-success" v-else-if="data.item.status == 1"> {{ $t("approved") }} </span>
          <span class="text-danger" v-else-if="data.item.status == 2"> {{ $t("declined") }} </span>
        </template>

        <template #cell(actions)="data">
          <template v-if="!editMode">
            <b-button :title="$t('set_completed')" @click="changeStatus(data.item.id, 1)" class="mr-1" size="sm" v-b-tooltip.hover v-if="data.item.status == 0" variant="outline-success">
              <feather-icon icon="CheckCircleIcon"/>
            </b-button>
            <b-button :title="$t('set_closed')" @click="changeStatus(data.item.id, 2)" class="mr-1" size="sm" v-b-tooltip.hover v-if="data.item.status == 0" variant="outline-danger">
              <feather-icon icon="XIcon"/>
            </b-button>
            <b-button :title="$t('set_pending')" @click="changeStatus(data.item.id, 0)" class="mr-1" size="sm" v-b-tooltip.hover v-if="data.item.status == 2" variant="outline-warning">
              <feather-icon icon="RefreshCwIcon"/>
            </b-button>
          </template>
          <span v-else></span>
        </template>
      </Table>
    </b-card>

    <b-modal
      v-model="editModal"
      size="sm"
      id="modal-center"
      @ok="submitNewAmount()"
    >
      <b-col cols="12">
        <b-form-group
          :label="$t('real_amount') + ':'"
          class="font-weight-bolder"
          id="real_amount"
          label-for="exchange-amount"
        >
          <validation-provider
            #default="{ errors }"
            name="real-amount"
            rules="required"
          >
            <b-form-input
              type="number"
              id="real-amount"
              v-model="newData.realAmount"
              @input="changeRealAmount"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col>
        <p>
          <span style="font-weight: bold">{{ $t('exchange_amount') }}: </span>
          <span>{{ newData.exchangeAmount }}</span>
        </p>
        <p>
          <span style="font-weight: bold">{{ $t('fee_hive_amount') }}: </span>
          <span>{{ newData.feeHiveAmount }}</span>
        </p>
      </b-col>
    </b-modal>

    <b-modal
      v-model="editExchangeModal"
      size="sm"
      id="modal-center"
      @ok="submitNewAmount()"
    >
      <b-col cols="12">
        <b-form-group
          :label="$t('exchange_amount') + ':'"
          class="font-weight-bolder"
          id="exchange_amount"
          label-for="exchange-amount"
        >
          <validation-provider
            #default="{ errors }"
            name="exchange-amount"
            rules="required"
          >
            <b-form-input
              type="number"
              id="exchange-amount"
              v-model="newData.exchangeAmount"
              @input="changeExchangeAmount"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col>
        <p>
          <span style="font-weight: bold">{{ $t('real_amount') }}: </span>
          <span>{{ newData.realAmount }}</span>
        </p>
        <p>
          <span style="font-weight: bold">{{ $t('fee_hive_amount') }}: </span>
          <span>{{ newData.feeHiveAmount }}</span>
        </p>
      </b-col>
    </b-modal>

    <b-modal
      v-model="editDiscountModal"
      size="sm"
      id="modal-center"
      @ok="submitNewAmount()"
    >
      <b-col cols="12">
        <b-form-group
          :label="$t('discount') + ':'"
          class="font-weight-bolder"
          id="exchange_discount"
          label-for="exchange-discount"
        >
          <validation-provider
            #default="{ errors }"
            name="exchange-discount"
            rules="required"
          >
            <b-form-input
              type="number"
              id="exchange-discount"
              v-model="newData.feeHiveAmount"
              @input="changeDiscount"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

<!--      <b-col>-->
<!--        <p>-->
<!--          <span style="font-weight: bold">{{ $t('real_amount') }}: </span>-->
<!--          <span>{{ newData.realAmount }}</span>-->
<!--        </p>-->
<!--        <p>-->
<!--          <span style="font-weight: bold">{{ $t('fee_hive_amount') }}: </span>-->
<!--          <span>{{ newData.feeHiveAmount }}</span>-->
<!--        </p>-->
<!--      </b-col>-->
    </b-modal>

  </div>
</template>

<script>

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import Table from "@/components/Common/Table2.vue";
import {CurrencyAmountFormat} from '@core/utils/CurrencyAmountFormat';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Table
  },
  computed: {
    rows() {
      return this.totalItems;
    },
  },
  data() {
    return {
      url: "/currency/admin",
      totalItems: 0,
      currentPage: 1,
      transactionID: null,
      newData: {
        exchangeAmount: null,
        realAmount: null,
        feeHiveAmount: null,
        discountPercent: null,
      },
      editModal: false,
      editExchangeModal: false,
      editDiscountModal: false,
      editMode: false,
      fields: [
        {
          label: this.$t('company'),
          key: "company_id"
        },
        {
          label: this.$t("amount"),
          key: "amount",
        },
        {
          label: this.$t("real_amount"),
          key: "real_amount",
        },
        {
          label: this.$t("exchange_amount"),
          key: "exchange_amount",
        },
        {
          label: this.$t("fee_hive_amount"),
          key: "fee_hive_amount",
        },
        {
          label: this.$t("fee_hive_percentage"),
          key: "fee_hive_percentage",
        },
        {
          label: this.$t("status"),
          key: "status",
        },
        {
          label: "actions",
          key: "actions"
        }
      ],
      transactions: []
    }
  },

  created() {
    this.getData();
  },

  methods: {

    amountFormat(amount, currency) {
      return CurrencyAmountFormat(amount, currency)
    },

    getData() {
      this.$http.post(`/currency/admin?page=${this.currentPage}`).then(response => {

        if (response.data.status == 200) {
          this.transactions = response.data.value.data;
          console.log(this.transactions);
          // this.totalItems = response.data.value.total;
          // this.currentPage = response.data.value.current_page;
        }

        this.isLoading = false;

      })
    },

    changeExchangeAmount() {
      let fee_hive_amount = this.newData.exchangeAmount * 1.75 / 100;
      this.newData.feeHiveAmount = (Math.round(fee_hive_amount * 100) / 100).toFixed(2);
      // this.newData.exchangeAmount = this.newData.exchangeAmount - this.newData.feeHiveAmount;
    },

    changeRealAmount() {

      let fee_hive_amount = this.newData.realAmount * 1.75 / 100;
      this.newData.feeHiveAmount = (Math.round(fee_hive_amount * 100) / 100).toFixed(2);
      this.newData.exchangeAmount = this.newData.realAmount - this.newData.feeHiveAmount;

    },

    changeDiscount() {
      this.newData.discountPercent = this.newData.feeHiveAmount * 100 / this.newData.exchangeAmount;
    },

    editAction(item) {
      this.transactionID = item.id
      this.editModal = !this.editModal
      this.newData.exchangeAmount = item.exchange_amount;
      this.newData.realAmount = item.real_amount;
      this.newData.feeHiveAmount = item.fee_hive_amount;
    },

    editExchangeAmount(item) {
      this.transactionID = item.id
      this.editExchangeModal = !this.editExchangeModal
      this.newData.exchangeAmount = item.exchange_amount;
      this.newData.realAmount = item.real_amount;
      this.newData.feeHiveAmount = item.fee_hive_amount;
    },

    editDiscount(item) {
      this.transactionID = item.id
      this.editDiscountModal = !this.editDiscountModal
      this.newData.exchangeAmount = item.exchange_amount;
      this.newData.realAmount = item.real_amount;
      this.newData.feeHiveAmount = item.fee_hive_amount;
    },

    submitNewAmount() {
      // if () {
      this.$http.post(`/currency/admin/change-amount/${this.transactionID}`, this.newData).then(response => {
        if (response.data.status == 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t(response.data.message),
              icon: "SuccessIcon",
              variant: "success",
            },
          });
          // location.reload()
        }
      })
      // }
    },

    changeStatus(id, status) {
      this.$http.post(`/currency/admin/change-status/${id}/${status}`).then(response => {
        if (response.data.status == 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t(response.data.message),
              icon: "SuccessIcon",
              variant: "success",
            },
          });
          location.reload()
        } else if (response.data.status == 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t(response.data.message),
              icon: "ErrorIcon",
              variant: "danger",
            },
          });
        }

        this.isLoading = false;

      })
    },
  },

}
</script>

<style scoped>

</style>